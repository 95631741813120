import classNames from 'classnames'
import { FC, ReactElement, useEffect, useState } from 'react'

import Button from './buttons/Button'

export interface ButtonsGroupVariant<T> {
  title: string
  value: T
  icon?: FC | ReactElement
  indicator?: string
  disabled?: boolean
  className?: string
  onClick?: () => void
  tooltip?: string
  testId?: string
}

export interface ButtonsGroupProps<T> {
  buttons: ButtonsGroupVariant<T>[]
  value?: T
  onChange?: (value: T, title?: string) => void
  disabled?: boolean
  className?: string
  size?: 'xl' | 'lg' | 'md' | 'sm' | 'xs'
}

// <T,> because it is a cleaner alternative to <T extends unknown> (moreover, unknown isn't allowed by our rules, so I would have to disable ESlint for that line which isn't ok): https://devtrium.com/posts/react-typescript-using-generics-in-react
const ButtonsGroup = <T,>(props: ButtonsGroupProps<T>): React.ReactElement => {
  const { buttons, disabled, onChange, className, value, size = 'xs' } = props
  const [currentValue, setCurrentValue] = useState(value as T)

  useEffect(() => {
    setCurrentValue(value as T)
  }, [value])

  const handleItemClick = (value: T, title: string): void => {
    if (value === currentValue) {
      return
    }

    setCurrentValue(value)
    onChange && onChange(value, title)
  }

  return (
    <div className={classNames('switch-group', className || '')}>
      {buttons.map((item, index) => {
        return (
          <Button
            className={classNames('switch-group__variant', 'button--switch', {
              active: item.value === currentValue,
              [`${item.className}`]: !!item.className,
            })}
            data-tip={item.tooltip}
            data-for={item.tooltip ? 'app-tooltip' : undefined}
            data-class={item.tooltip?.includes('<a ') ? 'clickable' : undefined}
            data-testid={item.testId}
            caption={item.title}
            icon={item.icon}
            buttonSize={size}
            indicator={item.indicator}
            disabled={disabled || item.disabled}
            onClick={(): void => {
              item.onClick && item.onClick()
              handleItemClick(item.value, item.title)
            }}
            key={`${index}__${item.title}__${item.value}`}
          />
        )
      })}
    </div>
  )
}

export default ButtonsGroup
